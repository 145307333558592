import React from "react";
import { graphql } from "gatsby";
import {
    Features,
    GutterWrapper,
    LeadGenerationButton,
    PageSectionImageText,
    PageSectionVideoText,
    PageTemplateWrapper
} from "@resourcehub/resourcehub-components";
import configGenerator from "@resourcehub/resourcehub-configuration";
import styled from "styled-components";
import { getImage } from "gatsby-plugin-image";

const SectionWrapper = styled.div`
    margin-bottom: 100px;
`;

const Page = ({ data }) => {
    const { site, sol_section_2_img, sol_section_4_img, tri_screen_lockup } = data;

    const config = configGenerator({
        market: site.siteMetadata.market,
        site: "education-secondary",
        locale: site.siteMetadata.lang,
        page: "solutions"
    });

    config.page.content.sections[0].panels[0].children = (
        <>
            <LeadGenerationButton {...config.page.content.sections[0].panels[0].custom.lead_generation_button} />
        </>
    );

    config.page.content.sections[1].panels[0].image.src = getImage(sol_section_2_img);
    config.page.content.sections[2].panels[0].image.src = getImage(tri_screen_lockup);
    config.page.content.sections[3].panels[0].image.src = getImage(sol_section_4_img);

    return (
        <PageTemplateWrapper config={config}>
            <GutterWrapper>
                <SectionWrapper>
                    <PageSectionVideoText {...config.page.content.sections[0]} />
                </SectionWrapper>
            </GutterWrapper>
            <GutterWrapper>
                <SectionWrapper>
                    <PageSectionImageText {...config.page.content.sections[1]} />
                </SectionWrapper>
            </GutterWrapper>
            <GutterWrapper>
                <SectionWrapper>
                    <PageSectionImageText {...config.page.content.sections[2]} />
                </SectionWrapper>
            </GutterWrapper>
            <GutterWrapper>
                <SectionWrapper>
                    <PageSectionImageText {...config.page.content.sections[3]} />
                </SectionWrapper>
            </GutterWrapper>
            <GutterWrapper>
                <Features {...config.page.content.sections[4].panels[0]} />
            </GutterWrapper>
        </PageTemplateWrapper>
    );
};

export default Page;

export const pageQuery = graphql`
    {
        site {
            ...SiteFragment
        }
        sol_section_1_img: file(relativePath: { eq: "images/sol-k12-section-1-img.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 649, height: 364, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        sol_section_2_img: file(relativePath: { eq: "images/sol-k12-section-2-img.png" }) {
            childImageSharp {
                gatsbyImageData(width: 879, height: 678, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        sol_section_4_img: file(relativePath: { eq: "images/sol-k12-section-4-img.png" }) {
            childImageSharp {
                gatsbyImageData(width: 935, height: 804, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        tri_screen_lockup: file(relativePath: { eq: "images/tri-screen-lockup.png" }) {
            childImageSharp {
                gatsbyImageData(width: 603, height: 379, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
    }
`;
